import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";

import dayjs from "dayjs";
import qs from "query-string";
import classNames from "classnames";
import { AxiosResponse } from "axios";
import { Dropdown, Menu } from "antd";
import useSWRInfinite from "swr/infinite";
import { DropTargetMonitor, useDrop } from "react-dnd";
import Observer from "@researchgate/react-intersection-observer";
import {
  Button,
  Checkbox,
  IllBills,
  Tooltip,
  Typography,
} from "@spenmo/splice";
import styles from "./index.module.scss";
import { downloadCSV, plural } from "utility";
import {
  IAssignToCycleResponse,
  IBillItem,
  BillItemStatus,
  IBillsResponse,
  PAYMENT_CYCLE_STATE,
  IPaymentCycleData,
  ISelectable,
  SortIdentifier,
  IValidateSelectedBillsResponse,
  ILatePaymentAlertInfo,
  BillDetailProps,
  IAssignPreviewResponse,
  IPaymentRunCalculateResponse,
  IMarkAsPaidResponse,
  IMarkAsPaidPreviewResponse,
} from "Views/Bills/V2/PaymentRun/types";
import {
  BILLS_PER_CYCLE_LIMIT,
  MARK_AS_PAID,
  PAYMENT_RUN_ALERT,
  Payment_Run_API_URL,
} from "Views/Bills/V2/PaymentRun/constant";
import {
  PaySelectedError,
  billSelectionError,
  filterAssignMenu,
  formatBudgetNames,
  mapToPaymentRunBillFilters,
  refreshSelectedBillIds,
  selectionNotAllowed,
  showCheckbox,
} from "Views/Bills/V2/PaymentRun/helper";

import APIClient from "API/Client";
import { useMatchMutate, useMutableData } from "API/useData";
import { useToaster } from "Views/Bills/V2/hooks";
import Sort from "Views/Bills/V2/PaymentRun/Sort";
import StatusInfo from "Views/Bills/V2/PaymentRun/StatusInfo";
import BillDetail from "Views/Bills/V2/PaymentRun/BillDetail";
import { TrxnFilterContext } from "Views/Transaction/Context";
import Amount from "Views/Bills/V2/PaymentRun/BillDetail/Amount";
import { useExportCycleContext } from "Views/Bills/V2/PaymentRun/context";
import ConfirmationAlert from "Views/Bills/V2/PaymentRun/ConfirmationAlert";
import PaymentRunContentShimmer from "Views/Bills/V2/PaymentRun/PaymentRunContentShimmer";
import { useSelector } from "react-redux";
import { API_URL } from "Views/Bills/V2/constants";
import { SaaSConfig } from "Views/Bills/V2/BillForm/type";
import { TEAM_PATHS } from "Route/Team/paths";

interface IPaymentCycleCard extends IPaymentCycleData {
  cycles: IPaymentCycleData[];
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
}
interface ICardRefElement extends HTMLDivElement {
  maxLeft?: number;
}

const PaymentCycleCard = ({
  id,
  name,
  state,
  status,
  statusDetails,
  cycles,
  date: cycleDate,
  isActive,
  containerRef,
}: IPaymentCycleCard) => {
  const history = useHistory();
  const matchMutate = useMatchMutate();
  const { appNotification } = useToaster();
  const queryParams = new URLSearchParams(window.location.search);
  const { cycleId: exportCycleId, setCycleId: setExportCycleId } =
    useExportCycleContext();
  const dashboardCurrency = useSelector(
    (state: any) => state.wallet?.data?.currency_code,
  );
  const cardRef = useRef<ICardRefElement>(null);
  const sortRef = useRef<HTMLDivElement>(null);
  const dropRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [bills, setBills] = useState<IBillItem[]>(null);
  const [totalSelectedPayout, setTotalSelectedPayout] = useState<number>(0);
  const [selectedBillIds, setSelectedBillIds] = useState<string[]>(null);
  const [skipLaterPaymentAlert, setSkipLaterPaymentAlert] =
    useState<boolean>(false);
  const [paginationData, setPaginationData] = useState<
    IBillsResponse["pagination"]
  >({
    currentPage: 0,
    totalPage: 0,
    totalRow: 0,
  });
  const noStatusInCurrentCycle = Object.keys(status).length === 0;
  const showPaySelected =
    bills?.length &&
    (PAYMENT_CYCLE_STATE.ACTIVE === state ||
      (PAYMENT_CYCLE_STATE.UPCOMING === state && noStatusInCurrentCycle));
  const showAssignTo =
    state === PAYMENT_CYCLE_STATE.UNSCHEDULED && selectedBillIds?.length > 0;
  const [showPaySelectedConfirmation, setShowPaySelectedConfirmation] =
    useState(false);
  const [latePaymentAlert, setLatePaymentAlert] =
    useState<ILatePaymentAlertInfo>({
      show: false,
      cycle: null,
      bills: null,
    });
  const currentCycle = cycles.find((cycle) => cycle.id === id);
  const [{ isOver }, dropTarget] = useDrop({
    accept: "NODE",
    drop: (
      item: { bill: IBillItem; cycle: IPaymentCycleData },
      monitor: DropTargetMonitor,
    ) => handleDropItem(item, monitor),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const handleDropItem = (
    data: { bill: IBillItem; cycle: IPaymentCycleData },
    _: DropTargetMonitor,
  ) => {
    const { cycle, bill } = data;
    if (cycle.id !== currentCycle.id) assignToCycle(currentCycle, [bill]);
  };

  const footerActionStyle = classNames(styles.footer, {
    [styles.paySelected]: showPaySelected,
    [styles.assignTo]: showAssignTo,
  });

  const { filter } = useContext(TrxnFilterContext);
  const billFilters = mapToPaymentRunBillFilters(filter);
  const [sortParam, setSortParam] = useState<SortIdentifier>(null);
  const {
    data: response,
    size,
    setSize,
    error,
    isValidating,
  } = useSWRInfinite(
    (pageIndex) => {
      const queryStr = qs.stringify({
        ...billFilters,
        page: pageIndex + 1,
        limit: BILLS_PER_CYCLE_LIMIT,
        ...(sortParam ? { sort: sortParam } : {}),
      });

      return `${Payment_Run_API_URL.billsPerCycle(id)}?${queryStr}`;
    },
    (url) =>
      APIClient.getData(url).then((res) => {
        return res.data.payload || [];
      }),
    {
      revalidateAll: true,
      revalidateOnFocus: false,
    },
  );

  const data = useMemo(
    () => response?.map((res) => res.list) || [],
    [response],
  );
  const isLoadingInitialData = !data && !error;
  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && data && !error && typeof data[size - 1] === "undefined");
  const isEmpty = data?.[0]?.length === 0;
  const isReachingEnd = isEmpty || paginationData.totalPage === size;
  const isRefreshing = isValidating && data && data.length === size;
  const { data: saasConfigResponse } = useMutableData(API_URL.saasConfig);
  const saasConfig: SaaSConfig = useMemo(
    () => saasConfigResponse?.data?.payload,
    [saasConfigResponse?.data?.payload],
  );
  const isSaasOnly = !saasConfig?.isPaymentEnabled;
  const [isAllPaymentRunBillIncluded, setIsAllPaymentRunBillIncluded] =
    useState<{ type: MARK_AS_PAID }>({
      type: null,
    });

  useEffect(() => {
    const calculateTotalAmount = async () => {
      if (saasConfig && isSaasOnly) {
        try {
          const response = await APIClient.postData(API_URL.calculateAmount, {
            billIDs: selectedBillIds,
            cycleID: id,
          });
          const {
            data: { payload },
          } = response as AxiosResponse<IPaymentRunCalculateResponse>;
          setTotalSelectedPayout(payload.totalAmount);
        } catch (err) {
          return;
        }
      }
    };
    if (currentCycle.isActive) {
      calculateTotalAmount();
    }
  }, [currentCycle, isSaasOnly, selectedBillIds, id, saasConfig]);

  useEffect(() => {
    if (response && response.length > 0) {
      setPaginationData(response[response.length - 1]?.pagination || {});
    }
  }, [response]);

  useEffect(() => {
    const exportCycle = async (cycleId: number) => {
      const queryStr = qs.stringify({
        ...billFilters,
        ...(currentCycle.state === PAYMENT_CYCLE_STATE.UNSCHEDULED
          ? {}
          : { cycleID: cycleId }),
        page: size,
        limit: BILLS_PER_CYCLE_LIMIT,
        ...(sortParam ? { sort: sortParam } : {}),
      });
      const response = await APIClient.getData(
        `${Payment_Run_API_URL.exportCycle}?${queryStr}`,
      );
      const { data } = response as AxiosResponse<string>;
      downloadCSV(
        data,
        `Payment_Run_${
          currentCycle.state === PAYMENT_CYCLE_STATE.UNSCHEDULED
            ? "unscheduled"
            : dayjs(cycleDate).format("YYYY_MMM_DD")
        }_bills`,
        true,
      );
    };
    if (exportCycleId !== null && exportCycleId === id) {
      exportCycle(exportCycleId);
      setExportCycleId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportCycleId]);

  useEffect(() => {
    const hasAllPagesData = data && data.length > 0;
    const hasFirstPageData = data && data[0] && data[0].length > 0;
    if (hasAllPagesData && hasFirstPageData) {
      const list = (data?.flat() as IBillItem[]) || [];
      const { payout } = currentCycle;
      let billIdsToSelect = selectedBillIds ?? [];
      let totalAmount = 0;
      if (payout && Object.keys(payout).length > 0 && payout?.billIDs) {
        const combinedIds = [
          ...refreshSelectedBillIds(payout.billIDs, list),
          ...refreshSelectedBillIds(billIdsToSelect, list),
        ];
        billIdsToSelect = Array.from(new Set(combinedIds));
        if (billIdsToSelect.length > 0) {
          totalAmount = list.reduce((acc, curr) => {
            if (billIdsToSelect.includes(curr.id)) {
              return acc + curr.paidAmount;
            }
            return acc;
          }, 0);
        }
      }
      setBills(
        list.map((bill) => ({
          ...bill,
          selectable: { isSelected: billIdsToSelect.includes(bill.id) },
        })),
      );
      setSelectedBillIds(billIdsToSelect);
      if (
        (saasConfig && !isSaasOnly) ||
        currentCycle.state === PAYMENT_CYCLE_STATE.PAST
      ) {
        if (payout && payout.totalAmount) {
          setTotalSelectedPayout(payout.totalAmount);
        } else {
          setTotalSelectedPayout(totalAmount);
        }
      }
    } else {
      setBills(null);
      setTotalSelectedPayout(0);
      setSelectedBillIds(null);
    }
  }, [data, cycles, id, currentCycle, saasConfig]);

  const cycleIdFromUrl = queryParams.get("cycleID");
  useEffect(() => {
    const card = cardRef?.current;
    if (card) {
      const rect = card.getBoundingClientRect();
      const unscheduledCard = cycles.find(
        (cycle) => cycle.state === PAYMENT_CYCLE_STATE.UNSCHEDULED,
      );
      let scrollToThisCard = unscheduledCard;
      if (cycleIdFromUrl) {
        scrollToThisCard = cycles.find((cycle) => cycle.id === +cycleIdFromUrl);
      }
      if (
        cardRef?.current &&
        rect?.left > 0 &&
        scrollToThisCard &&
        scrollToThisCard.id === id
      ) {
        cardRef.current.maxLeft = Math.max(
          rect?.left,
          isNaN(cardRef?.current.maxLeft) ? 0 : cardRef?.current.maxLeft,
        );
        containerRef.current.scrollLeft = cardRef.current.maxLeft - 300;
      }
    }
  }, [cardRef, containerRef, cycles, id, cycleIdFromUrl]);

  const assignToCycle = async (
    cycle: IPaymentCycleData,
    bills: IBillItem[],
    confirm: boolean = false,
  ) => {
    const { id, name } = cycle;
    let dueDateAfterPayment = false;

    const paymentAlreadyProcessedError = (err) => {
      if (err?.response.data.error.code === 4131) {
        appNotification.error({
          message:
            "You cannot move bills to a payment run that’s already processed",
        });
      } else if (cycle.state === PAYMENT_CYCLE_STATE.ADHOC) {
        appNotification.error({
          message: "You cannot move any of the bills to an adhoc payment run.",
        });
      } else {
        appNotification.error({
          message: `Uh-ohh, we couldn’t move the ${plural(
            bills.length,
            "bill",
          )} to the ${name} Payment Run. Please try again.`,
        });
      }
    };

    if (bills && !confirm) {
      try {
        const response = await APIClient.postData(
          Payment_Run_API_URL.assignPreview,
          {
            billIDs: bills.map((bill) => bill.id),
            cycleID: id,
          },
        );
        const {
          data: { payload },
        } = response as AxiosResponse<IAssignPreviewResponse>;
        dueDateAfterPayment = payload.isLatePayment;
      } catch (err) {
        paymentAlreadyProcessedError(err);
        return;
      }
    }

    if (!dueDateAfterPayment || confirm) {
      try {
        const response = await APIClient.putData(
          Payment_Run_API_URL.assignToCycle,
          {
            billIDs: bills.map((bill) => bill.id),
            cycleID: id,
            skipLatePaymentAlert: skipLaterPaymentAlert,
          },
        );
        const {
          data: { payload },
        } = response as AxiosResponse<IAssignToCycleResponse>;
        if (payload.isSuccess) {
          matchMutate(new RegExp(Payment_Run_API_URL.paymentCycles));
          appNotification.success({
            message: `You have successfully moved ${plural(bills.length, "bill")} to the ${name} Payment Run`,
          });
        } else {
          appNotification.error({
            message: `Uh-ohh, we couldn’t move the ${plural(
              bills.length,
              "bill",
            )} to the ${name} Payment Run. Please try again.`,
          });
        }
      } catch (err) {
        paymentAlreadyProcessedError(err);
      }
    } else {
      setLatePaymentAlert({ show: true, cycle: cycle, bills: bills });
    }
  };

  const handleVisibleChange = (visible: boolean) => {
    if (visible) {
      sortRef.current.classList.add(styles.sortByVisible);
      cardRef.current.classList.add(styles.activeCard);
    } else {
      sortRef.current.classList.remove(styles.sortByVisible);
      cardRef.current.classList.remove(styles.activeCard);
    }
  };

  const menu = (
    <Menu className={styles.dropdownMenu}>
      {cycles.filter(filterAssignMenu).map((cycle) => {
        return (
          <Menu.Item
            key={cycle.id}
            onClick={() =>
              assignToCycle(
                cycle,
                bills.filter((bill) => selectedBillIds.includes(bill.id)),
              )
            }
          >
            <Typography size="caption-m" variant="body-content">
              {cycle.name}
            </Typography>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const handleBillSelection = (
    e: ChangeEvent<HTMLInputElement>,
    id: string,
  ) => {
    const isChecked = e.target.checked;
    const allBills = [...bills];
    if (isChecked) {
      allBills.map((bill) => {
        if (bill.id === id) {
          bill.selectable.isSelected = true;
          if (!isSaasOnly) {
            setTotalSelectedPayout((totalSelectedPayout) =>
              parseFloat((totalSelectedPayout + bill.paidAmount).toFixed(2)),
            );
          }
        }
        return bill;
      });
      setSelectedBillIds([...(selectedBillIds || []), id]);
    } else {
      allBills.map((bill) => {
        if (bill.id === id) {
          bill.selectable.isSelected = false;
          if (!isSaasOnly) {
            setTotalSelectedPayout((totalSelectedPayout) =>
              parseFloat((totalSelectedPayout - bill.paidAmount).toFixed(2)),
            );
          }
        }
        return bill;
      });
      setSelectedBillIds(selectedBillIds.filter((_id) => _id !== id));
    }
    setBills([...allBills]);
  };

  const billCheckbox = (
    status: BillItemStatus,
    selectable: ISelectable,
    id: string,
  ) => {
    return (
      <Checkbox
        disabled={selectionNotAllowed(state, status.key)}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          handleBillSelection(e, id)
        }
        checked={selectable?.isSelected}
      />
    );
  };

  const billDetail = (props: BillDetailProps) => {
    const {
      billId,
      beneficiaryName,
      billNumber,
      dueDate,
      currency,
      paidAmount,
      attachmentURLs,
      status,
      isOverDue,
    } = props;
    const billDetailComponent = (
      <BillDetail
        id={billId}
        companyName={beneficiaryName}
        billNumber={billNumber}
        dueDate={dueDate}
        currency={currency}
        amount={paidAmount}
        fileUrls={attachmentURLs}
        status={status}
        isOverDue={isOverDue}
        isDraggable={currentCycle.state !== PAYMENT_CYCLE_STATE.ADHOC}
        bill={(bills || []).filter((bill) => billId === bill.id)[0]}
        currentCycle={currentCycle}
      />
    );

    return billDetailComponent;
  };

  const handleObserver = (e: IntersectionObserverEntry, unobserve) => {
    if (e.intersectionRatio !== 0) unobserve();
    if (!isReachingEnd && e.intersectionRatio !== 0) {
      setSize((size) => {
        return size + 1;
      });
    }
  };

  const paySelectedBills = async (selectedBillIds: string[]) => {
    const response = await APIClient.postData(
      Payment_Run_API_URL.validateSelectedBills,
      {
        billIDs: selectedBillIds,
        cycleID: id,
      },
    );
    const {
      data: { payload },
    } = response as AxiosResponse<IValidateSelectedBillsResponse>;
    if (payload.isEligible) {
      history.replace(
        `/bills/payment-run/pay/${btoa(JSON.stringify(selectedBillIds))}?cycleID=${id}`,
      );
    } else {
      if (payload.overlimitBillsCount) {
        appNotification.error({
          message: PaySelectedError(styles, {
            type: "overlimitBillsCount",
            data: payload.overlimitBillsCount,
          }),
          cta: "Refresh",
          onClickCTA: () => {
            matchMutate(new RegExp(Payment_Run_API_URL.paymentCycles));
          },
        });
      } else {
        appNotification.error({
          message: PaySelectedError(styles, {
            type: "overlimitBudgetNames",
            data: payload.overlimitBudgetNames,
          }),
          onClickCTA: () => {
            matchMutate(new RegExp(Payment_Run_API_URL.paymentCycles));
          },
        });
      }
    }
  };

  const handleDropRef = (element: HTMLDivElement) => {
    dropTarget(element);
    dropRef.current = element;
  };

  const latePaymentWording = useMemo(() => {
    return latePaymentAlert?.bills?.length === 1 ? "this bill" : "these bills";
  }, [latePaymentAlert.bills]);

  const handlePaymentAction = async () => {
    if (isSaasOnly) {
      try {
        const response = await APIClient.putData(
          Payment_Run_API_URL.previewMarkAsPaid,
          {
            billIDs: selectedBillIds,
            cycleID: id,
          },
        );
        const {
          data: { payload },
        } = response as AxiosResponse<IMarkAsPaidPreviewResponse>;
        setIsAllPaymentRunBillIncluded({
          type: payload.isAllPaymentRunBillIncluded
            ? MARK_AS_PAID.ALL_APPROVED
            : MARK_AS_PAID.PARTIAL_APPROVED,
        });
      } catch (err) {
        return;
      }
    } else {
      setShowPaySelectedConfirmation(true);
    }
  };

  const markAsPaid = async () => {
    try {
      const response = await APIClient.putData(Payment_Run_API_URL.markAsPaid, {
        billIDs: selectedBillIds,
        cycleID: id,
      });
      const {
        data: { payload },
      } = response as AxiosResponse<IMarkAsPaidResponse>;
      if (payload.isSuccess) {
        matchMutate(new RegExp(Payment_Run_API_URL.paymentCycles));
        appNotification.success({
          message: `Payment Run for ${name} marked as Paid' for entire payment run and 'Selected invoices are Marked as Paid' for selected invoices.`,
        });
      }
      if (payload.overlimitBudgetNames.length > 0) {
        appNotification.success({
          message: `Kindly review the ${formatBudgetNames(payload.overlimitBudgetNames)} ${plural(
            payload.overlimitBudgetNames.length,
            "budget",
          )} as they have exceeded the limit.`,
          cta: "View budgets",
          onClickCTA: () => history.push(TEAM_PATHS.TEAMS),
        });
      }
    } catch (err) {
      return;
    }
  };

  return (
    <div
      className={classNames(styles.container, { [styles.activeCard]: isOver })}
      ref={cardRef}
      data-testid={`payment-cycle-card-${id}`}
    >
      <div className={styles.header}>
        <Typography variant="body-content" size="s" weight={600}>
          {name}
        </Typography>
        {status?.key && (
          <StatusInfo status={status} statusDetails={statusDetails} />
        )}
      </div>
      <div className={styles.sortBy} ref={sortRef}>
        <Sort
          setSort={setSortParam}
          sortValue={sortParam}
          handleVisibleChange={handleVisibleChange}
        />
      </div>
      <div ref={handleDropRef} className={styles.dropContainer}>
        <div
          ref={contentRef}
          className={classNames(styles.content, {
            [styles.fixedFooter]: showPaySelected || showAssignTo,
          })}
        >
          {(bills || []).map(
            (
              {
                id,
                beneficiaryName,
                billNumber,
                dueDate,
                currency,
                paidAmount,
                attachmentURLs,
                status,
                isOverDue,
                selectable,
              },
              index,
            ) => {
              return (
                <div
                  key={id}
                  className={styles.billItem}
                  data-testid={`bill-item-${id}`}
                >
                  {showCheckbox(currentCycle) &&
                    (billSelectionError(state, status.key).length > 0 ? (
                      <Tooltip
                        className={styles.tooltip}
                        title={billSelectionError(state, status.key)}
                      >
                        {billCheckbox(status, selectable, id)}
                      </Tooltip>
                    ) : (
                      billCheckbox(status, selectable, id)
                    ))}
                  {(index + 1) % BILLS_PER_CYCLE_LIMIT === 0 ? (
                    <Observer root={dropRef.current} onChange={handleObserver}>
                      <div className={styles.observerWrapper}>
                        {billDetail({
                          billId: id,
                          beneficiaryName,
                          billNumber,
                          dueDate,
                          currency,
                          paidAmount,
                          attachmentURLs,
                          status,
                          isOverDue,
                        })}
                      </div>
                    </Observer>
                  ) : (
                    billDetail({
                      billId: id,
                      beneficiaryName,
                      billNumber,
                      dueDate,
                      currency,
                      paidAmount,
                      attachmentURLs,
                      status,
                      isOverDue,
                    })
                  )}
                </div>
              );
            },
          )}

          {!bills && !(isLoadingMore || isRefreshing) && (
            <div className={styles.noBills}>
              <div className={styles.content}>
                <IllBills size={120} />
                <Typography size="caption-m" variant="body-content">
                  No bills added to this payment run
                </Typography>
              </div>
            </div>
          )}
          {(isLoadingMore || isRefreshing) && <PaymentRunContentShimmer />}
          {!noStatusInCurrentCycle && (
            <div className={styles.totalPayout}>
              <Typography
                tag="p"
                variant="body-content"
                size="s"
                className={styles.bodyColor}
              >
                Total payout
              </Typography>
              <span className={styles.totalSelectedPayoutValue}>
                <Amount
                  currency={dashboardCurrency}
                  value={totalSelectedPayout}
                />
              </span>
            </div>
          )}
        </div>
      </div>
      {(showPaySelected || showAssignTo) && (
        <div className={footerActionStyle}>
          {showPaySelected && saasConfig && (
            <>
              <div className={styles.totalSelectedPayout}>
                <Typography
                  className={styles.selectedBillsLabel}
                  size="caption-m"
                  variant="body-content"
                >{`${
                  (selectedBillIds || []).length
                } bills selected`}</Typography>
                <span className={styles.totalSelectedPayoutValue}>
                  <Amount
                    currency={dashboardCurrency}
                    value={totalSelectedPayout}
                    saasOnly={isSaasOnly}
                  />
                </span>
              </div>
              <Button
                onClick={markAsPaid}
                disabled={!isActive || !selectedBillIds?.length}
                size="s"
                variant="primary"
              >
                {isSaasOnly
                  ? "Mark as Paid"
                  : `Pay ${(selectedBillIds || []).length} selected bills`}
              </Button>
            </>
          )}
          {showAssignTo && (
            <div className={styles.dropdown}>
              <Dropdown
                onVisibleChange={handleVisibleChange}
                overlay={menu}
                trigger={["click"]}
                placement="bottomCenter"
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
              >
                <Button size="s" variant="primary">
                  Assign to
                </Button>
              </Dropdown>
            </div>
          )}
        </div>
      )}

      {showPaySelectedConfirmation && (
        <ConfirmationAlert
          title={PAYMENT_RUN_ALERT.CONFIRM_PAY}
          show={showPaySelectedConfirmation}
          setShow={setShowPaySelectedConfirmation}
          confirmPay={() => paySelectedBills(selectedBillIds)}
        >
          <Typography variant="body-content" size="s">
            You are about to complete the payment for the selected bills within
            the {name} Payment Run. Any bills that remain unselected will be
            moved to the
            <Typography variant="body-content" size="s" weight={600}>
              {" "}
              "Unscheduled"
            </Typography>{" "}
            category.
          </Typography>
          <Typography variant="body-content" size="s">
            To process these unscheduled bills, you will need to include them in
            future Payment Runs. Would you like to proceed?
          </Typography>
        </ConfirmationAlert>
      )}
      {latePaymentAlert.show && (
        <ConfirmationAlert
          title={PAYMENT_RUN_ALERT.LATE_PAYMENT}
          show={latePaymentAlert.show}
          setShow={setLatePaymentAlert}
          confirmPay={() =>
            assignToCycle(latePaymentAlert.cycle, latePaymentAlert.bills, true)
          }
        >
          <Typography variant="body-content" size="s">
            Scheduling {latePaymentWording} to this Payment Run may result in
            late {plural(latePaymentAlert.bills.length, "payment", "s", false)}{" "}
            to your vendors since {latePaymentWording}’
            {latePaymentAlert.bills.length === 1 && "s"} due date is before the
            payment run date.
          </Typography>
          <div className={styles.latePaymentPreference}>
            <Checkbox
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setSkipLaterPaymentAlert(e.target.checked)
              }
              checked={skipLaterPaymentAlert}
            />
            <Typography variant="body-content" size="s">
              Don’t show again for this vendor
            </Typography>
          </div>
        </ConfirmationAlert>
      )}
      {isAllPaymentRunBillIncluded.type === MARK_AS_PAID.ALL_APPROVED && (
        <ConfirmationAlert
          title={PAYMENT_RUN_ALERT.MARK_AS_PAID_PARTIAL_ALL_APPROVED}
          show={isAllPaymentRunBillIncluded.type === MARK_AS_PAID.ALL_APPROVED}
          setShow={() => setIsAllPaymentRunBillIncluded({ type: null })}
          confirmPay={markAsPaid}
        >
          <Typography variant="body-content" size="s">
            All bills included in this payment run will be marked as paid. We
            recommend proceeding with this action only after ensuring successful
            payment of these bills.
          </Typography>
          <Typography variant="body-content" size="s">
            Would you like to confirm this action?
          </Typography>
        </ConfirmationAlert>
      )}
      {isAllPaymentRunBillIncluded.type === MARK_AS_PAID.PARTIAL_APPROVED && (
        <ConfirmationAlert
          title={PAYMENT_RUN_ALERT.MARK_AS_PAID_PARTIAL_APPROVED}
          show={
            isAllPaymentRunBillIncluded.type === MARK_AS_PAID.PARTIAL_APPROVED
          }
          setShow={() => setIsAllPaymentRunBillIncluded({ type: null })}
          confirmPay={markAsPaid}
        >
          <Typography variant="body-content" size="s">
            All selected bills included in this payment run will be marked as
            paid. We recommend proceeding with this action only after ensuring
            successful payment of these bills. Any bills that remain unselected
            will be moved to the
            <Typography variant="body-content" size="s" weight={600}>
              {" "}
              "Unscheduled"
            </Typography>{" "}
            bills section.
          </Typography>
          <Typography variant="body-content" size="s">
            To process these unscheduled bills, you will need to include them in
            future Payment Runs. Would you like to confirm?
          </Typography>
        </ConfirmationAlert>
      )}
    </div>
  );
};

export default PaymentCycleCard;
