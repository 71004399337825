import {
  IPaymentCycleResponse,
  IPaymentPeriodResponse,
} from "Views/Bills/V2/PaymentRun/types";
import { SortFilterData } from "Views/Bills/const";

export const BILLS_PER_CYCLE_LIMIT = 5;
export const Payment_Run_API_URL = {
  paymentPeriods: `/ms/spm-disbursement/v1/payment-run/periods`,
  paymentCycles: "/ms/spm-disbursement/v1/payment-run/cycles",
  billsPerCycle: (cycleId: number) =>
    `/ms/spm-disbursement/v1/payment-run/cycles/${cycleId}/bills`,
  assignToCycle: `/ms/spm-disbursement/v1/payment-run/cycles/assign`,
  assignPreview: `/ms/spm-disbursement/v1/payment-run/assign/preview`,
  validateSelectedBills: `/ms/spm-disbursement/v1/payment-run/payments/validate`,
  exportCycle: `/ms/spm-disbursement/v1/payment-run/export`,
  previewMarkAsPaid: `/ms/spm-disbursement/v1/bill/mark-as-paid/preview`,
  markAsPaid: `/ms/spm-disbursement/v1/bill/mark-as-paid`,
};

export const initialCycleResponse: { data: IPaymentCycleResponse } = {
  data: {
    payload: {
      passed: [],
      upcoming: [],
    },
  },
};

export const initialPeriodResponse: { data: IPaymentPeriodResponse } = {
  data: {
    payload: [],
    status: 200,
  },
};

export enum CYCLE_STATUS_ENUM {
  SENDING_PAYMENT = "sending_payment",
  PAID = "paid",
  REVERSED = "reversed",
  WAITING_FUNDS = "waiting_funds",
}

export enum BILL_STATUS_ENUM {
  PENDING_APPROVAL = "waiting_approval",
  PENDING = "pending",
  APPROVED = "approved",
  COMPLETED = "completed", // Sending Payment
  RECEIVED = "received", // Paid
  REVERSED = "reversed",
  BUDGET_OVERLIMIT = "limit_exceeded",
  WAITING_FOR_FUNDS = "waiting_funds",
  PAID_VIA_OTHER = "paid_outside_spenmo",
}

export const BILL_STATUS_VARIANT = {
  [BILL_STATUS_ENUM.PENDING_APPROVAL]: "warning",
  [BILL_STATUS_ENUM.PENDING]: "info",
  [BILL_STATUS_ENUM.APPROVED]: "info",
  [BILL_STATUS_ENUM.COMPLETED]: "info",
  [BILL_STATUS_ENUM.RECEIVED]: "success",
  [BILL_STATUS_ENUM.REVERSED]: "danger",
  [BILL_STATUS_ENUM.BUDGET_OVERLIMIT]: "warning",
  [BILL_STATUS_ENUM.WAITING_FOR_FUNDS]: "info",
  [BILL_STATUS_ENUM.PAID_VIA_OTHER]: "success",
};

interface SortFilterDataPaymentRun extends SortFilterData {
  activeLabel: string;
}

export const PAYMENT_RUN_SORT_BY: SortFilterDataPaymentRun[] = [
  {
    id: "due_date:asc",
    title: "Due date",
    activeLabel: "Due date",
  },
  {
    id: `amount:desc`,
    title: "Amount(High to Low)",
    activeLabel: "Amount",
  },
  {
    id: "recipient_name:asc",
    title: "Recipient name (A to Z)",
    activeLabel: "Recipient",
  },
];

export enum PAYMENT_RUN_ALERT {
  LATE_PAYMENT = "Your payment might get delayed",
  CONFIRM_PAY = "Completing Payment for Selected Bills",
  MARK_AS_PAID_PARTIAL_APPROVED = "Payment Confirmation",
  MARK_AS_PAID_PARTIAL_ALL_APPROVED = "Marking Payment Run as Paid",
}

export enum MARK_AS_PAID {
  ALL_APPROVED = "All_APPROVED",
  PARTIAL_APPROVED = "PARTIAL_APPROVED",
}
